import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertController, LoadingController, Platform} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {parseJson} from "@angular/cli/utilities/json-file";


// import {LoginPage} from '../../pages/login/login';

/*
  Generated class for the ApiProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/


interface Token {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: any;
  token_type: string;
}

@Injectable({providedIn: 'root'})
export class ApiProvider {
  // tslint:disable-next-line:variable-name
  // tslint:disable-next-line:variable-name
  private _token;
  private _currentUser: Boolean;


  constructor(
    public http: HttpClient,
    private alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public platform: Platform,
    public router: Router
  ) {
  }

  getPacientes() {
    const accessToken = this.getToken();
    return this.http.get<any>(
      environment.SERVER + '/api/getpacientes',
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken)
      }
    );
  }

  predictnutrition(datos) {
    let header = new HttpHeaders();
    header.set('Content-Type', 'application/json')
    return this.http.post<any>(
      environment.SERVERRISK + '/predictnutrition',
      JSON.stringify(datos),
      {
        headers: header
      }
    );
  }
  predictnutrition2(datos) {
    let header = new HttpHeaders();
    header.set('Content-Type', 'application/json')
    return this.http.post<any>(
      environment.SERVERRISK + '/predictnutrition2',
      JSON.stringify(datos),
      {
        headers: header
      }
    );
  }
  getPacientesList() {
    const accessToken = this.getToken();
    return this.http.get<any>(
      environment.SERVER + '/api/getpacienteslist',
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken)
      }
    );
  }

  getRecommendationsForPatient(datos) {
    let header = new HttpHeaders();
    header.set('Content-Type', 'application/json')
    return this.http.post<any>(
      environment.SERVERNUTRI + '/getRecommendationsForPatient',
      JSON.stringify(datos),
      {
        headers: header
      }
    );
  }

  getDietas(datos) {
    let header = new HttpHeaders();
    header.set('Content-Type', 'application/json')
    return this.http.post<any>(
      environment.SERVERRISK + '/getdiets',
      JSON.stringify(datos),
      {
        headers: header
      }
    );
  }


  getPaciente(id) {
    const accessToken = this.getToken();
    return this.http.get<any>(
      environment.SERVER + '/api/getpaciente/' + id,
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken)
      }
    );
  }

  altaPaciente(datos) {

    const accessToken = this.getToken();
    let header = new HttpHeaders();
    header.set('Content-Type', 'application/json')
    header.append('Authorization', 'Bearer ' + accessToken)
    /**
     * Generamos la petición HTTP Post, con los parametros y las cabeceras (Usamos HttpClient en vez de Http, que ya está en desuso)
     */
    return this.http.post<any>(
      environment.SERVER + '/api/altapaciente',
      JSON.stringify(datos),
      {
        'headers': header
      }
    );
  }


  getEstadoCivil() {
    const accessToken = this.getToken();
    return this.http.get<any>(
      environment.SERVER + '/api/getestadocivil',
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken)
      }
    );
  }


  login(datos) {
    const params = new HttpParams()
      .set('email', datos.email)
      .set('password', datos.password)
    /**
     * Generamos la petición HTTP Post, con los parametros y las cabeceras (Usamos HttpClient en vez de Http, que ya está en desuso)
     */
    return this.http.post<any>(
      environment.SERVER + '/api/login',
      params,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }


  logout() {
    this.alertCtrl.create({
      // title: 'Cerrar sesión',
      message: 'Está a punto de cerrar sesión ¿Está seguro de que desea continuar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: 'Cerrar sesión',
          handler: () => {
            this._logout();
          }
        }
      ]
    }).then(a => a.present());
  }

  /**
   *  Muestra un mensaje modal indicando que se ha producido un error en la conexión o en el environment.SERVER
   * tslint:disable-next-line:no-redundant-jsdoc
   * @{Promise<any>}
   */
  presentNetError() {
    return new Promise((resolve) => {
      this.alertCtrl.create({
        message: 'Se ha producido un problema con su solicitud.<br/>Por favor inténtelo mas tarde.',
        buttons: [
          {
            text: 'Aceptar',
            handler: () => {
              resolve(true);
            }
          }
        ]
      }).then(alert => alert.present());
    });
  }

  predicciones(datos){
    const accessToken = this.getToken();
    let header = new HttpHeaders();
    header.set('Content-Type', 'application/json')
    header.append('Authorization', 'Bearer ' + accessToken)
    /**
     * Generamos la petición HTTP Post, con los parametros y las cabeceras (Usamos HttpClient en vez de Http, que ya está en desuso)
     */
    return this.http.post<any>(
      environment.SERVER + '/api/predicciones',
      JSON.stringify(datos),
      {
        'headers': header
      }
    );
  }



  // En la compilacion de escritorio solo tiene estas plataformas
  // private desktopPlatform = ['cordova', 'core', 'mobile'];

  // tslint:disable-next-line:max-line-length
  isDesktop(check: 'ios' | 'ipad' | 'iphone' | 'android' | 'phablet' | 'tablet' | 'cordova' | 'capacitor' | 'electron' | 'pwa' | 'mobile' | 'mobileweb' | 'desktop' | 'hybrid' = 'desktop') {
    // return false;
    // tslint:disable-next-line:max-line-length
    // this.online = !(this.network.type === undefined || this.network.type === null || this.network.type === 'unknown' || this.network.type === 'none');
    return !this.platform.is('android');

    // return this.platform.is(check);
  }


  getToken() {
    return localStorage.getItem('access_token');
  }

  private _logout() {
    const accessToken = this.getToken();
    localStorage.removeItem('access_token');
    localStorage.removeItem('paciente');

    this.router.navigate(['/']);
    setTimeout(() => {
      window.location.reload();
    }, 0);
  }

}
